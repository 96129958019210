import { List } from "immutable";
import React, { ReactElement, useEffect, useRef, useState } from "react";
import { Routes, Location, useLocation } from "react-router";
import {
	TransitionAnimationProvider,
	type TSwitchAnimation,
	type TTransitionAnimationOptions
} from "context/transitionAnimationContext";
import requestRenderFrame, { RenderFrameHandle } from "utils/ui/requestRenderFrame";

const SECOND_IN_MS = 1000;
const UNMOUNT_TIMEOUT = SECOND_IN_MS * 1.5;
const RENDER_FRAME_TIMEOUT = SECOND_IN_MS * 0.5;

type TSwitchTransitionsProps = {
	animation?: TSwitchAnimation;
	reverse?: boolean;
};

type TRenderLocation = {
	location: Location;
	addTime: number;
	contextState: TTransitionAnimationOptions;
};

export const SwitchTransitions: FC<TSwitchTransitionsProps> = ({
	children,
	animation = "fade-move-from-center",
	reverse = false
}) => {
	const location = useLocation();
	const [renderLocations, setRenderLocations] = useState(() => List<TRenderLocation>());
	const unmountTimeoutsRef = useRef<number[]>([]);

	useEffect(() => {
		// on the first time, add the location without animating it
		if (renderLocations.size === 0) {
			setRenderLocations(
				List([
					{
						location,
						addTime: Date.now(),
						contextState: {
							animation,
							animate: false,
							reverse,
							movement: "standstill"
						}
					}
				])
			);
		} else {
			// when there are already some locations in `renderLocations`,
			// animate the entering of the new location and the exiting of the previous location.
			// but only if they have a different pathnames
			// first, add new location to the dom and prepare the new and previous locations for an animation
			const lastItem = renderLocations.last();
			const samePathname = lastItem?.location.pathname === location.pathname;

			if (!!lastItem && !samePathname) {
				const outItem: TRenderLocation = {
					...lastItem,
					contextState: {
						animation,
						animate: false,
						reverse,
						movement: "out"
					}
				};
				const inItem: TRenderLocation = {
					location,
					addTime: Date.now(),
					contextState: {
						animation,
						animate: false,
						reverse,
						movement: "in"
					}
				};

				// we should clear the previous transitions of the same location if they exist
				renderLocations.forEach((renderLocation, index) => {
					if (renderLocation.location.pathname === location.pathname) {
						window.clearTimeout(unmountTimeoutsRef.current.at(index));
						unmountTimeoutsRef.current.splice(index, 1);
					}
				});

				setRenderLocations(
					renderLocations
						.slice(0, -1)
						.filter(renderLocation => renderLocation.location.pathname !== location.pathname)
						.push(outItem, inItem)
				);
			}

			const scheduleUnmount = () => {
				if (!samePathname) {
					unmountTimeoutsRef.current.push(
						window.setTimeout(() => {
							setRenderLocations(renderLocations => renderLocations.shift());
							unmountTimeoutsRef.current.shift();
						}, UNMOUNT_TIMEOUT)
					);
				}
			};

			// wait until the items are added to the DOM and rendered
			let renderFrame: RenderFrameHandle | null = requestRenderFrame(() => {
				// trigger the transition animations
				setRenderLocations(renderLocations => {
					const oldOutItem = renderLocations.get(-2);
					const oldInItem = renderLocations.last();
					if (renderLocations.size < 2 || !oldOutItem || !oldInItem) {
						if (!oldInItem) return renderLocations;
						return renderLocations.setIn([-1], { ...oldInItem, location });
					}
					const outItem: TRenderLocation = {
						...oldOutItem,
						contextState: {
							animation,
							animate: true,
							reverse,
							movement: "out"
						}
					};

					const inItem: TRenderLocation = {
						...oldInItem,
						contextState: {
							animation,
							animate: true,
							reverse,
							movement: "in"
						}
					};

					return renderLocations.slice(0, -2).push(outItem, inItem);
				});

				renderFrame = null;
				scheduleUnmount();
			}, RENDER_FRAME_TIMEOUT);

			return () => {
				if (renderFrame) {
					renderFrame.destroy();
					scheduleUnmount();
				}
			};
		}

		return () => undefined;
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [location.key]);

	useEffect(() => {
		const current = unmountTimeoutsRef.current;
		return () => {
			while (current.length > 0) {
				window.clearTimeout(current.shift());
			}
		};
	}, []);

	return (
		<>
			{renderLocations.map<ReactElement>(renderLocation => {
				return (
					<TransitionAnimationProvider key={renderLocation.location.pathname} options={renderLocation.contextState}>
						<Routes location={renderLocation.location}>{children}</Routes>
					</TransitionAnimationProvider>
				);
			})}
		</>
	);
};
