import React, { Suspense } from "react";
import { Skeleton } from "components/ui/Skeleton";
import { TeamsAuthWall } from "../teamsAuthWall";

const NewTicketPage = React.lazy(() =>
	import("components/pages/NewTicketPage/NewTicketPage").then(module => ({ default: module.NewTicketPage }))
);

export const TeamsNewTicketPage = () => (
	<TeamsAuthWall>
		<Suspense fallback={<Skeleton height={"100%"} width={"100%"} />}>
			<NewTicketPage />
		</Suspense>
	</TeamsAuthWall>
);
