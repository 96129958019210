import classNames from "classnames";
import isNil from "lodash/isNil";
import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { matchPath, Link, useLocation } from "react-router";
import { StaticChip } from "components/ui/chips/StaticChip";
import { FavoriteIcon } from "components/ui/Icons/FavoriteIcon";
import { Tooltip } from "components/ui/Tooltip";
import { Typography } from "components/ui/Typography";
import { routes } from "routes/routes";
import { flattenRoutes } from "utils/router/utils";
import { requirePropertyOf } from "utils/security";
import { useStyles } from "./styles";

const ICON_SIZE_PX = 24;

interface IProps {
	routeKey: keyof typeof routes;
	Icon: IconComponent;
	text: string;
	minimized: boolean;
	indication?: "new" | "beta";
}

export const TabOption: FC<IProps> = ({ routeKey, Icon, text, minimized, indication }) => {
	const classes = useStyles();
	const { t } = useTranslation(undefined, { keyPrefix: "navigation.indications" });

	const location = useLocation();

	const route = requirePropertyOf(routes, routeKey);

	const flattenedRoutes = useMemo(
		() => (route ? flattenRoutes({ routes: { [routeKey]: route } }) : []),
		[route, routeKey]
	);

	const active = useMemo(
		() =>
			flattenedRoutes.some(route => {
				const matches = Array.isArray(route.matches) ? route.matches : [route.matches];
				return matches.some(path => !isNil(matchPath(path, location.pathname)));
			}),
		[flattenedRoutes, location.pathname]
	);

	const goToUrlMatch = useMemo(() => matchPath(location.pathname, route.main), [location.pathname, route.main]);

	const canClick = goToUrlMatch === null;

	const classString = classNames(classes.option, { [classes.active]: active });

	const textElement = (
		<Typography noWrap variant="body_reg">
			{text}
		</Typography>
	);

	const content = (
		<>
			<div className={classes.title}>
				<Icon size={ICON_SIZE_PX} />
				<div className={classNames(classes.text, { [classes.minimized]: minimized })}>{textElement}</div>
			</div>

			{indication && (
				<StaticChip variant="regular" size="small" SuffixIcon={FavoriteIcon}>
					{t(indication)}
				</StaticChip>
			)}
		</>
	);

	return (
		<Tooltip
			content={textElement}
			placement="right"
			visible={!minimized ? false : undefined}
			className={classes.tooltip}>
			<div className={classNames(classes.container, { [classes.minimized]: minimized })}>
				{canClick ? (
					<Link className={classString} to={route.main}>
						{content}
					</Link>
				) : (
					<div className={classString}>{content}</div>
				)}
			</div>
		</Tooltip>
	);
};
