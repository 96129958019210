import { lazy } from "react";
import { BetaTriggerPage } from "components/pages/BetaTriggerPage";
import { Homepage } from "components/pages/Homepage";
import { RequestsPage } from "components/pages/RequestsPage";
import { Route, TShouldRenderFunction } from "routes/route";

const AccessReportPage = lazy(() =>
	import("components/pages/AccessReportPage").then(module => ({ default: module.AccessReportPage }))
);
const AccessReviewDashboardPage = lazy(() =>
	import("components/pages/AccessReviewDashboardPage").then(module => ({ default: module.AccessReviewDashboardPage }))
);
const AccessReviewTemplatePage = lazy(() =>
	import("components/pages/AccessReviewTemplatePage").then(module => ({ default: module.AccessReviewTemplatePage }))
);
const AccessReviewTemplatesPage = lazy(() =>
	import("components/pages/AccessReviewTemplatesPage").then(module => ({ default: module.AccessReviewTemplatesPage }))
);
const AdminAccessReviewPage = lazy(() =>
	import("components/pages/AdminAccessReviewPage").then(module => ({ default: module.AdminAccessReviewPage }))
);
const AdminResourceAccessReviewPage = lazy(() =>
	import("components/pages/AdminResourceAccessReview").then(module => ({
		default: module.AdminResourceAccessReviewPage
	}))
);
const AdminSubordinateAccessReviewPage = lazy(() =>
	import("components/pages/AdminSubordinateAccessReview").then(module => ({
		default: module.AdminSubordinateAccessReviewPage
	}))
);
const AuditLogsPage = lazy(() =>
	import("components/pages/AuditLogsPage").then(module => ({ default: module.AuditLogsPage }))
);
const BulkActionsPage = lazy(() =>
	import("components/pages/BulkActionsPage").then(module => ({ default: module.BulkActionsPage }))
);
const BundlesPage = lazy(() =>
	import("components/pages/BundlesPage").then(module => ({ default: module.BundlesPage }))
);
const CreateIntegrationPage = lazy(() =>
	import("components/pages/CreateIntegrationPage").then(module => ({ default: module.CreateIntegrationPage }))
);
const CreateRulePage = lazy(() =>
	import("components/pages/CreateRulePage").then(module => ({ default: module.CreateRulePage }))
);
const EditRulePage = lazy(() =>
	import("components/pages/CreateRulePage/EditRulePage").then(module => ({ default: module.EditRulePage }))
);
const IdentityGraphPage = lazy(() =>
	import("components/pages/IdentityGraphPage").then(module => ({ default: module.IdentityGraphPage }))
);
const IntegrationPage = lazy(() =>
	import("components/pages/IntegrationPage").then(module => ({ default: module.IntegrationPage }))
);
const IntegrationResourcePage = lazy(() =>
	import("components/pages/IntegrationResourcePage").then(module => ({ default: module.IntegrationResourcePage }))
);
const IntegrationResourceRolePage = lazy(() =>
	import("components/pages/IntegrationResourceRolePage").then(module => ({
		default: module.IntegrationResourceRolePage
	}))
);
const IntegrationsPage = lazy(() =>
	import("components/pages/IntegrationsPage").then(module => ({ default: module.IntegrationsPage }))
);
const MyPermissionsPage = lazy(() =>
	import("components/pages/MyPermissionsPage").then(module => ({ default: module.MyPermissionsPage }))
);
const NewRequestPage = lazy(() =>
	import("components/pages/NewRequestPage").then(module => ({ default: module.NewRequestPage }))
);
const PastRequestsPage = lazy(() =>
	import("components/pages/PastRequestsPage").then(module => ({ default: module.PastRequestsPage }))
);
const PoliciesPage = lazy(() =>
	import("components/pages/PoliciesPage").then(module => ({ default: module.PoliciesPage }))
);
const ProfilePage = lazy(() =>
	import("components/pages/ProfilePage").then(module => ({ default: module.ProfilePage }))
);
const RequestsLogPage = lazy(() =>
	import("components/pages/RequestsLogPage").then(module => ({ default: module.RequestsLogPage }))
);
const ResourceAccessReportPage = lazy(() =>
	import("components/pages/ResourceAccessReportPage").then(module => ({ default: module.ResourceAccessReportPage }))
);
const RulesPage = lazy(() => import("components/pages/RulesPage").then(module => ({ default: module.RulesPage })));
const SettingsPage = lazy(() =>
	import("components/pages/SettingsPage").then(module => ({ default: module.SettingsPage }))
);
const SubordinateAccessReportPage = lazy(() =>
	import("components/pages/SubordinateAccessReportPage").then(module => ({
		default: module.SubordinateAccessReportPage
	}))
);
const TasksPage = lazy(() => import("components/pages/TasksPage").then(module => ({ default: module.TasksPage })));
const UserPage = lazy(() => import("components/pages/UserPage").then(module => ({ default: module.UserPage })));
const UsersPage = lazy(() => import("components/pages/UsersPage").then(module => ({ default: module.UsersPage })));
const WorkflowsPage = lazy(() =>
	import("components/pages/WorkflowsPage").then(module => ({ default: module.WorkflowsPage }))
);
const CreateWorkflowPage = lazy(() =>
	import("components/pages/WorkflowsPage/components/EditWorkflow").then(module => ({
		default: module.CreateWorkflowPage
	}))
);
const EditWorkflowPage = lazy(() =>
	import("components/pages/WorkflowsPage/components/EditWorkflow").then(module => ({
		default: module.EditWorkflowPage
	}))
);
const WorkflowsWebhooksPage = lazy(() =>
	import("components/pages/WorkflowsWebhooksPage").then(module => ({ default: module.WorkflowsWebhooksPage }))
);

export type TRoutes = { [key: string]: Route };

const renderIfAdmin: TShouldRenderFunction = ({ user: { isAdmin } }) => isAdmin;

const shouldRenderPolicies: TShouldRenderFunction = ({ user }) => Boolean(renderIfAdmin({ user }));

const shouldRenderAccessReview: TShouldRenderFunction = ({ user, company }) =>
	Boolean(renderIfAdmin({ user }) && company?.enableAccessReviews);

const renderIfHasAccessReports: TShouldRenderFunction = ({ user: { hasAccessReviewReports }, company }) =>
	Boolean(hasAccessReviewReports && company?.enableAccessReviews);

const shouldRenderIdentityGraph: TShouldRenderFunction = ({ user }) => Boolean(renderIfAdmin({ user }));

const shouldRenderBulkActions: TShouldRenderFunction = ({ user }) => Boolean(renderIfAdmin({ user }));

export const routes = {
	home: new Route({
		matches: ["/"],
		inAnimation: {
			name: "fade-move-from-center"
		},
		outAnimation: {
			name: "fade-move-from-center",
			reverse: true
		},
		Page: Homepage,
		pageId: "Home"
	}),
	requests: new Route({
		matches: ["/requests"],
		Page: RequestsPage,
		pageId: "Requests",
		subRoutes: {
			myPermissions: new Route({
				matches: ["/myPermissions"],
				Page: MyPermissionsPage,
				pageId: "MyPermissions"
			}),
			past: new Route({
				matches: ["/past"],
				Page: PastRequestsPage,
				pageId: "PastRequests"
			})
		}
	}),
	newRequest: new Route({
		matches: ["/newRequest"],
		Page: NewRequestPage,
		pageId: "NewRequest",
		inAnimation: {
			name: "fade-move-from-center"
		},
		outAnimation: {
			name: "fade-move-from-center",
			reverse: true
		},
		hideSideNav: true
	}),
	identityGraph: new Route({
		matches: ["/graph"],
		animationOrder: 1,
		Page: IdentityGraphPage,
		pageId: "IdentityGraph",
		shouldRender: shouldRenderIdentityGraph
	}),
	workflows: new Route({
		matches: ["/workflows"],
		animationOrder: 2,
		Page: WorkflowsPage,
		pageId: "Workflows",
		shouldRender: renderIfAdmin,
		subRoutes: {
			webhooks: new Route({
				matches: ["/webhooks"],
				Page: WorkflowsWebhooksPage,
				pageId: "WorkflowsWebhooks",
				shouldRender: renderIfAdmin
			}),
			editWorkflow: new Route({
				matches: ["/edit/:workflowId"],
				Page: EditWorkflowPage,
				pageId: "EditWorkflow",
				shouldRender: renderIfAdmin
			}),
			createWorkflow: new Route({
				matches: ["/create"],
				Page: CreateWorkflowPage,
				pageId: "CreateWorkflow",
				shouldRender: renderIfAdmin
			})
		}
	}),
	rules: new Route({
		matches: ["/rules"],
		animationOrder: 50,
		Page: RulesPage,
		pageId: "Rules",
		shouldRender: shouldRenderBulkActions,
		subRoutes: {
			createRule: new Route({
				hideSideNav: true,
				matches: ["/create"],
				animationOrder: 1,
				Page: CreateRulePage,
				pageId: "RulesCreate",
				shouldRender: shouldRenderBulkActions
			}),
			editRule: new Route({
				hideSideNav: true,
				matches: ["/edit/:ruleId"],
				animationOrder: 1,
				Page: EditRulePage,
				pageId: "RulesEdit",
				shouldRender: shouldRenderBulkActions
			})
		}
	}),
	integrations: new Route({
		matches: ["/integrations"],
		animationOrder: 3,
		Page: IntegrationsPage,
		pageId: "Integrations",
		shouldRender: renderIfAdmin,
		subRoutes: {
			createIntegration: new Route({
				animationOrder: 1,
				matches: ["/create"],
				Page: CreateIntegrationPage,
				pageId: "CreateIntegration",
				shouldRender: renderIfAdmin
			}),
			integration: new Route({
				animationOrder: 3,
				matches: ["/:integrationId"],
				Page: IntegrationPage,
				pageId: "Integration",
				shouldRender: renderIfAdmin,
				subRoutes: {
					integrationResource: new Route({
						matches: ["/resources/:integrationResourceId"],
						animationOrder: 2,
						Page: IntegrationResourcePage,
						pageId: "IntegrationResource",
						shouldRender: renderIfAdmin,
						subRoutes: {
							integrationResourceRole: new Route({
								matches: ["/roles/:integrationResourceRoleId"],
								animationOrder: 1,
								Page: IntegrationResourceRolePage,
								pageId: "IntegrationResourceRole",
								shouldRender: renderIfAdmin
							})
						}
					})
				}
			})
		}
	}),
	bulkActions: new Route({
		matches: ["/bulkActions"],
		Page: BulkActionsPage,
		shouldRender: shouldRenderBulkActions,
		pageId: "BulkActions"
	}),
	bundles: new Route({
		matches: ["/bundles"],
		animationOrder: 4,
		Page: BundlesPage,
		pageId: "Bundles",
		shouldRender: renderIfAdmin
	}),
	policies: new Route({
		matches: ["/policies"],
		animationOrder: 5,
		Page: PoliciesPage,
		pageId: "Policies",
		shouldRender: shouldRenderPolicies
	}),
	tasks: new Route({
		matches: ["/tasks"],
		animationOrder: 6,
		Page: TasksPage,
		pageId: "Tasks"
	}),
	accessReview: new Route({
		matches: ["/accessReview"],
		animationOrder: 7,
		Page: AccessReviewDashboardPage,
		pageId: "AccessReview",
		shouldRender: shouldRenderAccessReview,
		subRoutes: {
			report: new Route({
				matches: ["/reports/:reportId"],
				animationOrder: 1,
				Page: AdminAccessReviewPage,
				pageId: "AccessReviewReport",
				shouldRender: shouldRenderAccessReview
			}),
			adminResourceReview: new Route({
				matches: ["/resources/:resourceId"],
				animationOrder: 2,
				Page: AdminResourceAccessReviewPage,
				pageId: "AdminResourceAccessReview",
				shouldRender: shouldRenderAccessReview
			}),
			adminSubordinateReview: new Route({
				matches: ["/subordinates/:subordinateId"],
				animationOrder: 3,
				Page: AdminSubordinateAccessReviewPage,
				pageId: "AdminSubordinateAccessReview",
				shouldRender: shouldRenderAccessReview
			}),
			template: new Route({
				matches: ["/templates"],
				animationOrder: 4,
				Page: AccessReviewTemplatesPage,
				pageId: "AccessReviewTemplatesPage",
				shouldRender: shouldRenderAccessReview,
				subRoutes: {
					create: new Route({
						matches: ["/create"],
						animationOrder: 1,
						Page: AccessReviewTemplatePage,
						pageId: "AccessReviewTemplatePage",
						shouldRender: shouldRenderAccessReview
					}),
					edit: new Route({
						matches: ["/edit/:templateId"],
						animationOrder: 2,
						Page: AccessReviewTemplatePage,
						pageId: "AccessReviewTemplatePage",
						shouldRender: shouldRenderAccessReview
					})
				}
			})
		}
	}),
	accessReport: new Route({
		matches: ["/accessReport", "/accessReport/:accessReviewId"],
		animationOrder: 8,
		Page: AccessReportPage,
		pageId: "AccessReport",
		shouldRender: renderIfHasAccessReports,
		subRoutes: {
			subordinateReview: new Route({
				matches: ["/subordinates/:subordinateId"],
				animationOrder: 1,
				Page: SubordinateAccessReportPage,
				pageId: "SubordinateAccessReport",
				shouldRender: renderIfHasAccessReports
			}),
			resourceReview: new Route({
				matches: ["/resources/:resourceId"],
				animationOrder: 2,
				Page: ResourceAccessReportPage,
				pageId: "ResourceAccessReport",
				shouldRender: renderIfHasAccessReports
			})
		}
	}),
	requestsLog: new Route({
		matches: ["/requestHistory"],
		animationOrder: 9,
		Page: RequestsLogPage,
		pageId: "RequestsLog",
		shouldRender: renderIfAdmin
	}),
	auditLogs: new Route({
		matches: ["/auditLogs"],
		animationOrder: 10,
		Page: AuditLogsPage,
		pageId: "AuditLogs",
		shouldRender: renderIfAdmin
	}),
	settings: new Route({
		matches: ["/organizationSettings"],
		animationOrder: 11,
		shouldRender: renderIfAdmin,
		Page: SettingsPage,
		pageId: "Settings"
	}),
	users: new Route({
		matches: ["/users"],
		animationOrder: 12,
		Page: UsersPage,
		subRoutes: {
			user: new Route({
				matches: ["/:userId"],
				Page: UserPage,
				pageId: "User",
				shouldRender: renderIfAdmin
			})
		},
		shouldRender: renderIfAdmin,
		pageId: "Users"
	}),
	profile: new Route({
		matches: ["/profile"],
		animationOrder: 13,
		Page: ProfilePage,
		pageId: "Profile",
		inAnimation: {
			name: "fade-move-from-center"
		},
		outAnimation: {
			name: "fade-move-from-center",
			reverse: true
		}
	}),
	betaTrigger: new Route({
		matches: ["/beta", "/beta/*"],
		Page: BetaTriggerPage,
		pageId: "BetaTrigger"
	})
};
