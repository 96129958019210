import React, { useEffect } from "react";
import { Route, Routes, useLocation, useNavigate } from "react-router";
import { LoadingUserInfoPage } from "components/App/components/LoginWall/components/LoadingUserInfoPage";
import { LoginPromptPage } from "components/App/components/LoginWall/components/LoginPromptPage";
import { useAuthContext } from "context/authContext";
import { useCompanyContext } from "context/companyContext";
import { useAuthenticatedUser } from "hooks/useAuthenticatedUser";
import { useOpenGlobalErrorModal } from "hooks/useGlobalError";
import ApiError from "utils/errors/apiError";
import { TErrorId } from "utils/errors/mapApiErrorParams";
import { Callback } from "./components/Callback";

export const LoginWall: FC = ({ children }) => {
	const { isLoggedIn, signIn } = useAuthContext();
	const location = useLocation();
	const navigate = useNavigate();
	const { user, clearUser, reloadUser } = useAuthenticatedUser();
	const {
		state: { company },
		actions: { loadCompany }
	} = useCompanyContext();
	const openGlobalErrorModal = useOpenGlobalErrorModal();

	useEffect(() => {
		const listener = (e: Event) => {
			const customEvent = e as CustomEvent;
			const searchParams = customEvent.detail as URLSearchParams;
			const token = searchParams.get("token");

			if (token) {
				signIn(token);
				void reloadUser();
			}
		};
		addEventListener("loginSuccess", listener);
		return () => removeEventListener("loginSuccess", listener);
	}, [reloadUser, signIn]);

	useEffect(() => {
		const listener = (e: Event) => {
			const customEvent = e as CustomEvent;
			const searchParams = customEvent.detail as URLSearchParams;
			const errorIdBase64 = searchParams.get("errorId");
			let errorId: TErrorId = "request.internalServerError";
			if (errorIdBase64) errorId = Buffer.from(errorIdBase64, "base64").toString("utf-8") as TErrorId;
			openGlobalErrorModal(new ApiError({ errorId }));
		};
		addEventListener("loginFailure", listener);
		return () => removeEventListener("loginFailure", listener);
	}, [openGlobalErrorModal]);

	useEffect(() => {
		if (!isLoggedIn && !!user) clearUser();
		if (user) {
			void loadCompany();
		}
	}, [clearUser, loadCompany, isLoggedIn, user]);

	if (!isLoggedIn)
		return (
			<Routes>
				<Route path="/">
					<Route index element={<LoginPromptPage />} />
					<Route path="*" element={<LoginPromptPage />} />
					<Route path="/login/callback" element={<Callback onLogin={reloadUser} />} />
				</Route>
			</Routes>
		);

	if (isLoggedIn && (!user || !company)) return <LoadingUserInfoPage />;

	if (location.pathname === "/login/callback") {
		void navigate("/");
	}

	return <>{children}</>;
};
