import { useRef } from "react";
import { useLocation, Location } from "react-router";

/**
 * Keep track of the current location changes in order to provide the previous rendered location that's different
 * from the current rendered one
 * @param currentLocation - The current location to compare with the useLocation hook
 * @returns The previous location that was rendered
 */
export default function usePreviousLocation(currentLocation: Location | null = null): Location | null {
	const currentLocationHook = useLocation();
	const _currentLocation = currentLocation ?? currentLocationHook;

	const lastRenderLocationRef = useRef<Location | null>(null);
	const lastLocationRef = useRef<Location | null>(null);

	if (!!lastRenderLocationRef.current && lastRenderLocationRef.current.key !== _currentLocation.key)
		lastLocationRef.current = lastRenderLocationRef.current;

	lastRenderLocationRef.current = _currentLocation;

	return lastLocationRef.current;
}
