import React, { useEffect } from "react";
import { useNavigate, useSearchParams } from "react-router";
import { LoadingDots } from "components/ui/LoadingDots";
import { useAuthContext } from "context/authContext";

export const Callback: FC<{ onLogin: () => Promise<void> }> = ({ onLogin }) => {
	const [searchParams] = useSearchParams();
	const { signIn } = useAuthContext();
	const token = searchParams?.get("token") || null;
	const navigate = useNavigate();

	useEffect(() => {
		let timeout: NodeJS.Timeout;
		if (!token) {
			// The timeout is to allow some time to load the search params, if not, the user will be redirected to the login page
			timeout = setTimeout(() => {
				void navigate("/");
			}, 3000);
		} else {
			signIn(token);
			const login = async () => {
				await onLogin();
				void navigate("/");
			};
			void login();
		}
		return () => timeout && clearTimeout(timeout);
	}, [token, navigate, onLogin, signIn]);

	return <LoadingDots center />;
};
