import React from "react";
import { TeamsAuthWall } from "../teamsAuthWall";

const ProfilePage = React.lazy(() =>
	import("components/pages/ProfilePage/ProfilePage").then(module => ({ default: module.ProfilePage }))
);

export const TeamsProfilePage = () => (
	<TeamsAuthWall>
		<ProfilePage hideBackButton />
	</TeamsAuthWall>
);
