import "./init";
import "./utils/devtools/useWhatChanged.ts";
//  Must be loaded first
import React from "react";
import { createRoot } from "react-dom/client";
import App from "components/App";
import { logrocketInit } from "utils/logrocket";
import "./i18n";
import "./index.css";

void logrocketInit();
const rootElement = document.getElementById("app");
createRoot(rootElement!).render(React.createElement(App));
